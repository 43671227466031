.differentImpact {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  
  .sectionTitle {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    color: #ffffff; /* White text */
  }
  
  .boxContainer {
    display: flex;
    flex-direction: row-reverse; /* Change layout to stack vertically */
    justify-content: center;
    align-items: center; /* Optional: Center align items horizontally */
    gap: 300px; /* Space between the boxes */
  }

  
  .infoBox h3,
  .infoBox p {
    color: #ffffff; /* White text */
  }
  
  .infoBox h3 {
    margin-bottom: 10px;
  }
  
  .infoBox p {
    font-size: 14px;
    margin: 0;
  }
  @media (max-width: 768px){
    .boxContainer {
      
    }
  }