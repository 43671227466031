.footerContainer {
    background-Color:  rgba(51,51,51,0.9);
    color: #fff;
    padding: 20px;
    text-align: center;
    width: 100%;
  }
  .footerContent {
    max-width: 1200px;
    margin: 0 auto;
  }
  link {
    color: #ddd;
    text-decoration: none;
    margin: "0 10px";
  }
