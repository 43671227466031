.infoBox {
    width: 450px;
    min-height: var(--min-height, 420px); /* Default to 420px if not provided */
    background-color: rgba(0, 0, 0,0.5);
    display: flex-start;
    flex-direction: column;
    align-items: center;
    padding-top: 2px;
    justify-content: flex-start; /* Ensures title stays at the top */
    border-radius: 10px;
    padding-left: 40px; /* Add padding for better spacing */
    padding-right: 40px; /* Add padding for better spacing */
    box-sizing: border-box; /* Ensure padding doesn't affect width */
    padding-bottom: 40px;
  }
  
  .infoBox h3 {
    font-size: 26px;
    margin-bottom: 20px;
    color: #ffffff;
    text-align: center;
  }
  
  .infoBox-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .infoBox p {
    font-size: 18px;
    margin: 0;
    color: #ffffff;
    text-align: justify; /* Justify text alignment */
    margin-bottom: 20px;
  }
  
  .infoBox.ltr {
    direction: ltr; /* Left-to-right layout */
    text-align: left; /* Default alignment for English */
  }
  
  .infoBox.rtl {
    direction: rtl; /* Right-to-left layout */
    text-align: right; /* Default alignment for Hebrew */
  }
  @media (max-width: 768px){
    .infoBox {
      width: 350px;
  }}
