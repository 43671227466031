.hero2 {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center; /* Align content to the right */
  align-items: center;
  position: relative; /* Keep it relative unless you need a specific stacking context */
  opacity: 0;
  transition: opacity 1.5s ease-in-out;
  z-index: 1; /* Ensure hero section doesn't overlap navigation */
}

.hero2.fade-in {
  opacity: 1;
}

.heroTextBox {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75); /* Dark background for contrast */
  border-radius: 10px;
  text-align: center;
  color: #fff;
  padding:20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.textbox-container{
  width: 50%;
}
.heroDescription {
  font-size: 18px;
  line-height: 1.5;
}
@media (max-width: 768px){
  .textbox-container {
    width: 90%; /* Takes more width on smaller screens */
    margin-right: 40px;
  }

  .heroTextBox {
    width: 100%;
    min-width: unset; /* Prevents forced minimum width */
    max-width: 350px; /* Adjust max width for smaller screens */
    background-color: rgba(0, 0, 0, 0.6);
  }
}
.custom-divider{
  height: 10px;
}