.page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: Arial, sans-serif;
}

/* Contact Form Container */
.contactForm-container {
  width: 40%; /* Matches MiniInfoBox width */
  background-color: rgba(0, 0, 0,0.5);
  border-radius: 10px;
  padding: 30px; /* More padding for spacious layout */
  color: #ffffff; /* White text */
}

/* Dynamic Direction */
.contactForm-container.ltr {
  direction: ltr; /* Left-to-right */
  text-align: left;
}

.contactForm-container.rtl {
  direction: rtl; /* Right-to-left */
  text-align: right;
}

/* Form Title */
.form-title {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
  color: #ffffff;
}

/* Form Description */
.form-description {
  font-size: 18px;
  text-align: right;
  margin-bottom: 30px;
  color: rgba(255, 255, 255, 0.8); /* Lighter text for description */
}

/* Form Styling */
.contactForm {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Larger spacing between fields */
}

/* Form Group */
.form-group {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 8px; /* Spacing between label and input */
  font-size: 18px!important;
  color: #ffffff;
  direction: rtl;
  text-align: right;
}

select{
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    color: #ffffff;
    font-size: 14px;
    width: 100%;
}

option {
    background-color: rgba(150, 141, 141, 0.534);
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: #333333;
    padding: 10px;
  }
input,
textarea {
  width: 100%;
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.1); /* Input field background */
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  color: #ffffff; /* White text */
  font-size: 14px;
  box-sizing: border-box;
  direction: rtl;
  text-align: right;
}

input:focus,
textarea:focus {
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
}

textarea {
  resize: none; /* Prevent resizing */
  height: 100px;
}

/* Button Container */
.button-container {
  margin-top: 20px; /* Adds extra space above the button */
  
  display: flex;
  justify-content: center;
}

@media (max-width: 768px){
  .contactForm-container {
    width: 95%;
    /* background-color: rgba(0, 0, 0, 0.6); Dark background for contrast */
    /* margin-right: 20px; */
    /* margin-left: 20px; */
  }
}
