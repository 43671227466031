.phoneSection {
    width: 100%;
    padding: 20px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: right;
    text-align: center;
    direction: ltr; /* Default to left-to-right */

  }
  
  .phoneSection[lang="heb"] {
    direction: rtl; /* Right-to-left for Hebrew */
  }
  
  .sectionTitle {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .btn-box{
    margin-bottom: 50px;
    align-items: right;
  }
  .sectionDescription {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: justify;
    direction: rtl; /* Right-to-left layout */
    
  }
  .content-phone{
    flex-direction: row-reverse;
    display: flex;
    align-items: center;
    text-align: center;
    gap:120px;
    
  }
  .phoneContainer {
    display: flex;
    align-items: left;
    gap:20px;
    width:120%;
    margin-bottom: 100px;
    margin-left: 30px;
  }
  .phone{
    max-width: 100%;

  }
  .phoneView {

    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;


  }
  @media (max-width: 768px){
    .content-phone{
      flex-direction: column;
      margin-right: 15px;
    }
    .phoneContainer {
      width:90%;
      margin-left:0px;
    }
  }