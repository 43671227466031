/* Initial state: Element is hidden below */
.slide-up-container {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  
  /* Animated state: Element slides up */
  .slide-up-container.visible {
    opacity: 1;
    transform: translateY(0);
  }
  