.shining-button {
    background-color: grey;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .shining-button:hover {
    background-color: white;
    color: grey;
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.8);
  }
  