/* Initial state: Slide from the right */
.slide-right-container {
    opacity: 0;
    transform: translateX(50px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  
  /* Initial state: Slide from the left */
  .slide-left-container {
    opacity: 0;
    transform: translateX(-50px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  
  /* Visible state: Slide into place */
  .slide-right-container.visible,
  .slide-left-container.visible {
    opacity: 1;
    transform: translateX(0);
  }
  