/* Common Styling */

.page {
  padding: 30px;
  color: #ffffff;
  font-family: Arial, sans-serif;
  flex-direction: column;
  margin: 0 auto;
  align-items: right;
  text-align: right;
  direction: rtl;
}



.paragraph {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 0.9);
  direction: rtl;
  text-align: right;
}

/* Center and Responsive Design */
@media (max-width: 768px) {
  .big-header {
    font-size: 28px;
  }

  .small-header {
    font-size: 20px;
  }

  .paragraph {
    font-size: 14px;
  }
}
