.img-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
    
  .boxContainer1 {
    display: flex;
    flex-direction: row-reverse; /* Change layout to stack vertically */
    justify-content: center;
    align-items: center; /* Optional: Center align items horizontally */
    gap: 50px; /* Space between the boxes */
  }

  @media (max-width: 768px){
    .boxContainer1 {
    flex-direction: column;
    }
  }
