.commentsSection {
    margin-top: 15px;
    border-top: 1px solid #aaa;
    padding-top: 10px;
    background-color: #aaa!important;
    direction: rtl; /* Right-to-left layout */
    text-align: right; /* Default alignment for Hebrew */
  }

  .comment {
    margin-bottom: 10px;
  }