/* Section Styling */
.TenCommandmentSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 20px;
    min-height: 100vh;
  }
  .row-commandment{
    flex-direction: row-reverse;
    display: flex;
    gap: 65px;
  }
  /* Section Title */
  .sectionTitle {
    font-size: 2rem;
    color: #ffffff;
    margin-bottom: 40px;
    text-align: center;
  }
  
  /* MiniInfoBox Animation */

  /* MiniInfoBox Styling */
  .MiniInfoBox {
    background-color: rgba(255, 255, 255, 0.1); /* Slight transparency for MiniInfoBox background */
    border-radius: 10px;
  }
  @media (max-width: 768px){
    .row-commandment{
      flex-direction: column;
      gap: 0px;
    }
  }