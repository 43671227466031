/* Overlay Styling */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears above other elements */
  }
  
  /* Popup Container */
  .popup-container {
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    border-radius: 10px;
    padding: 20px 30px;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.3); /* Subtle glow effect */
    text-align: center;
    max-width: 400px;
    color: #ffffff;
    font-family: Arial, sans-serif;
  }
  
  /* Popup Title */
  .popup-title {
    font-size: 24px;
    margin-bottom: 15px;
    color: #ffffff;
  }
  
  /* Popup Info Text */
  .popup-info {
    font-size: 16px;
    margin-bottom: 20px;
    color: rgba(255, 255, 255, 0.8); /* Slightly lighter text for info */
  }
  
  /* Popup Button Container */
  .popup-button-container {
    display: flex;
    justify-content: center;
  }
  