.card {
    border: 3px solid #ccc;
    border-radius: 8px;
    padding-bottom: 50px;
    margin: 20px 0;
    cursor: pointer;
    padding-top: 5px;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.7);
  }
  
  .card.smallView {
    max-height: 80px;
    overflow: hidden;
  }
  
  .card.fullView {
    max-height: none;
    border: 5px solid #ccc;
    cursor: default;
  }
  
  .cardTitle {
    font-size: 18px;
    margin: 5px 0;
  }
  
  .cardSnippet {
    font-size: 14px;
    color: #555;
    
  }
  
  .cardOwner,
  .cardTopic,
  .cardDescription {
    margin: 5px 0;
    font-size: 14px;
  }
    
  
  .commentsSection {
    margin-top: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    width:80%;
  }
  .descriptionFull{
    padding:5px;
    border: 0.5px solid #ccc;
    width:80%;
    color:rgba(255, 255, 255, 0.53);
    margin-bottom: 20px;
    direction: rtl;
  }
  .description{
    width:80%;
    color:rgba(255, 255, 255, 0.53);
    direction: rtl;
  }
  .cardHeader{
    color:rgba(255, 255, 255, 0.53);
  }

  .moreinfo.rtl{
    display: flex;
    flex-direction: row;
    direction: rtl; /* Left-to-right layout */
    text-align: left;
    color : rgba(255, 255, 255, 0.53);
  }
  .moreinfo.ltr{
    display: flex;
    flex-direction: row;
    direction: ltr; /* Left-to-right layout */
    text-align: right;
    color : rgba(255, 255, 255, 0.53);
  }
.moreinfoP{
  padding: 10px;
}
.moreinfoPL{
  padding: 10px;
  color:rgba(255, 255, 255, 0.814)
}
.minimizeButton{
  margin-top: 20px;
}
.commentsContainer{
  margin-top: 20px;
  width:80%;
  background-color: red;
}
.text{
  flex-direction: row;
  display: flex;
  color: white;
  font-size: 18px;

}

@media (max-width: 768px){
  .card {
    width: 300px;
    margin: 0px ;
  }
}