@font-face {
    font-family: 'HebrewFontHeader'; /* Choose a name for your font */
    src: url('../../content/guttman/Heebo-Bold.ttf') format('truetype'); /* Make sure the path is correct */
  }
  @font-face {
    font-family: 'HebrewFont'; /* Choose a name for your font */
    src: url('../../content/guttman/Assistant-Regular.ttf') format('truetype'); /* Make sure the path is correct */
  }
  .hebrew-text {
    font-family: 'HebrewFontHeader', serif;
    direction: rtl; /* Ensures right-to-left text alignment for Hebrew */
  }
  .hebrew-text-blue{
    font-family: 'HebrewFontHeader', serif;
    direction: rtl; /* Ensures right-to-left text alignment for Hebrew */
    color : rgb(7, 14, 197);
  }
  .hebrew-header1-W {
    font-family: 'HebrewFontHeader', serif;
    direction: rtl; 
    font-size:48px;
    color:white;
}
.hebrew-header2-W {
  font-family: 'HebrewFontHeader', serif;
  direction: rtl; 
  font-size:36px;
  color:white;
}

  .backround{
    width: 100%; /* Full width of the viewport */
    background: linear-gradient(to bottom, rgba(5, 10, 164, 0.785), rgba(0, 0, 0, 0.81)); 
    background-size: cover; /* Ensures the image covers the entire section */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents tiling of the image */
    display: flex; /* Optional: For aligning content */
    justify-content: center; /* Optional: Center content horizontally */
    align-items: center; /* Optional: Center content vertically */
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif; /* Optional: Set font style */
    border: none;
  }
  .backround1{
    border: none;
    margin: 0;
    padding: 0;
    width: 100%; /* Full width of the viewport */
    background: linear-gradient(to bottom,  rgba(0, 0, 0, 0.81),rgba(5, 10, 164, 0.785)); 
    background-size: cover; /* Ensures the image covers the entire section */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents tiling of the image */
    display: flex; /* Optional: For aligning content */
    justify-content: center; /* Optional: Center content horizontally */
    align-items: center; /* Optional: Center content vertically */
    font-family: Arial, sans-serif; /* Optional: Set font style */
  
  }
  .backround2{
    border: none;
    margin: 0;
    padding: 0;
    width: 100%; /* Full width of the viewport */
    background: linear-gradient(to bottom,  rgba(0, 0, 0, 0.81)0%,rgba(5, 10, 164, 0.785)50%,rgba(0, 0, 0, 0.81)100%); 
    background-size: cover; /* Ensures the image covers the entire section */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents tiling of the image */
    display: flex; /* Optional: For aligning content */
    justify-content: center; /* Optional: Center content horizontally */
    align-items: center; /* Optional: Center content vertically */
    font-family: Arial, sans-serif; /* Optional: Set font style */
  
  }
  html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  .content-container {
    margin: 0 auto; /* Centers the content */
    
    padding: 0 20px; /* Adds padding inside the container */
    max-width: 1200px; /* Restricts the width of the content */
    background-color: transparent; /* Ensures the background does not cover the margins */
    position: relative; /* Ensures this element's background does not overlap the margin */
  }
  .table{
    width:700px;
  }
  @media (max-width: 768px){
    .table{
      width:350px;
    }
  }