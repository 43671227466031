.commentForm {
    margin: 20px 0;
    background-color: #f9f9f984;
    padding: 15px;
    border-radius: 8px;
    direction: rtl; /* Right-to-left */
  text-align: right;
  }
  
  .formGroup {
    margin-bottom: 15px;
    direction: rtl; /* Right-to-left */
  text-align: right;
  }
  
  .formGroup label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    direction: rtl; /* Right-to-left */
  text-align: right;
  }
  
  textarea {
    width: 100%;
    height: 60px;
    direction: rtl; /* Right-to-left */
  text-align: right;
  }
  
  .submitButton {
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    direction: rtl; /* Right-to-left */
  text-align: right;
  }
  