
.searchSection{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; /* Center items horizontally */
  padding: 20px;
  background-color: rgba(51, 51, 51,0.7); /* Matches the InfoBox background */
  color: #ffffff; /* White text */
}

.searchSection.ltr {
    direction: ltr; /* Left-to-right layout */
    text-align: left; /* Default alignment for English */
  }
  
  .searchSection.rtl {
    direction: rtl; /* Right-to-left layout */
    text-align: right; /* Default alignment for Hebrew */
  }

.searchContainer {
  padding-right : 10px;
  padding-left : 10px;
    align-items: center;
}

.searchInput {
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  color: #ffffff;
  font-size: 14px;
  width: 400px;
}
.filterSelect{
  position: relative;
  font-family: Arial;
  padding-right : 10px;
  padding-left : 10px;
}
.filterSelect select{
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    color: #ffffff;
    font-size: 14px;
    width: 200px;
}

.filterSelect option {
    background-color: rgba(150, 141, 141, 0.534);
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: #333333;
    padding: 10px;
  }
.ButtonContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Button1{
  margin :5px;
}

@media (max-width: 768px){
  .searchSection{

    flex-direction: column;

  }
  .searchContainer {
    width:80%;
    display: flex;
  }

}
