.forum-page {
    background-color: rgba(51, 51, 51,0.3); /* Background for the page */
    align-items: center;
    min-height: 100vh;
    font-family: Arial, sans-serif;
    
  }
.addCardSection{
    padding-top:50px;
    padding-bottom:50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; /* Center items horizontally */
}
.interdaction{
  font-size: 14px;
  text-align: center;
  margin: 30px;
  width: 60%;
  color: rgba(255, 255, 255, 0.8); /* Lighter text for description */
}
.top{
  display: flex;
    flex-direction: column;
    align-items: center;
}
.forum-section{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text-int{
  font-size: 20px;
  direction: rtl; /* Right-to-left layout */
  text-align: justify;
}
@media (max-width: 768px){
  .forum-page {
    width: 100%;}
  
  .forum-section{width: 150px;}
}