.miniInfoBox-layout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background-color: rgba(19, 18, 18, 0.6);
    padding: 20px;
    padding-left:30px;
    border-radius: 10px;
    width: 500px;
    box-sizing: border-box;
    min-height: 190px; 
  }
  
  .miniInfoBox-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: justify; /* Ensures text is justified */
    flex: 1; /* Ensures text takes the remaining space */
  }
  
  .miniInfoBox-logo {
    width: 60px; /* Fixed width */
    height: 60px; /* Fixed height */
    flex-shrink: 0; /* Prevents the logo container from resizing */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding-left:10px;
    overflow: hidden; /* Prevents image overflow */
  }
  
  .miniInfoBox-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensures the image scales proportionally */
  }
  
  .miniInfoBox-text h3 {
    margin: 0;
    font-size: 24px;
    color: #ffffff;
  }
  
  .miniInfoBox-text p {
    margin: 5px 5px 5px;
    font-size: 18px;
    color: #ffffff;
  }

  .rtl-layout {
    direction: rtl; /* Right-to-left layout for Hebrew */
    text-align: right; /* Align text to the right for Hebrew */
  }
  
  .ltr-layout {
    direction: ltr; /* Left-to-right layout for English */
    text-align: left; /* Align text to the left for English */
  }
  @media (max-width: 768px){
    .miniInfoBox-layout {
      width: 350px;
    }
  }