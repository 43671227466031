/* MethodSection.css */

  .header-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .parallelBoxes {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    gap: 200px;
    margin-bottom: 40px;
  }
  
  .textBox {
    flex: 1;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .centerBox {
    text-align: center;
  }
  
  .centerBox h1 {
    margin: 20px 0;
    font-size: 24px;
  }
  
  .centerBox p {
    margin-bottom: 20px;
    font-size: 16px;
  }
  .rotated-image {
    transform: rotate(90deg); /* Rotate the image 90 degrees to the right */
    width: 80%; /* Adjust width to make the image smaller */
    height: 50%; /* Maintain aspect ratio */
  }


  .phone{
    width:650%;
    height: auto;
  }
  .singleBox{
    flex-direction: column;
    display: flex;
  }
  @media (max-width: 768px){
    .boxContainer{
      flex-direction: column;
    }
    .rotated-image {
      transform: rotate(0deg);
    }
    .parallelBoxes {
      flex-direction: column;
      gap: 50px;
    }
  }