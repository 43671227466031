/* Overlay for the form */
.addCardForm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above other elements */
}

/* Form Container */
.addCardForm-container {
  min-width:500px;
  background-color:  rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  border-radius: 10px;
  padding: 20px 30px;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.3); /* Subtle glow effect */
  text-align: center;
  max-width: 700px;
  color: #ffffff;
  font-family: Arial, sans-serif;
}

/* Form Title */
.form-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #ffffff;
}

/* Form Group */
.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  color: #ffffff;
  font-size: 14px;
  box-sizing: border-box;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
}

.form-group textarea {
  resize: none;
  height: 100px;
}

/* Form Actions */
.form-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}
