/* Header.css */
.logo:hover{
  cursor: pointer;
}
.container-header{
  display: flex;
  justify-content: center; /* Centers the navbar items */
  align-items: center;
  width: 100%;
}
header {
    background-color: #000;
    color: #fff;
    /* display: flex;
    justify-content: space-between;
    flex-direction: row;*/
    align-items: center; 
    justify-content: space-between; 
    align-items: center;
    padding: 10px ;
}
.logo{
  padding-right: 150px;
  margin-left:55px; 
}
.container-nav-ico{
 display: flex;
 align-items: center;
 flex-direction: row;
}

.social-icons {
    display: flex;
    gap: 10px;
}


/* Navbar.css */
nav{
    padding-left: 60px;
    justify-content: center; 
}
nav ul {
    list-style: none;
    
}

nav ul li a {
    color: #fff;
    text-decoration: none;
    font-size:18px;
    direction: rtl;
    padding-left: 55px;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  font-size: 2rem;
  cursor: pointer;
  color: white;
  margin-top: 10px;
}

.nav-links {
  display: flex;
  gap: 100px;
  
  
}

@media (max-width: 768px) {
  .hamburger {
      display: block;
  }

  nav{
    padding-right: 150px;
    margin-left: 70px;
    padding-bottom: 7px;
}
  .nav-links {
      display: none;
      position: fixed;
      top: 80px;
      right: 0;
      z-index: 10;
      background-color: rgba(0, 0, 0, 0.9);
      width: 100%;
      flex-direction: column-reverse;
      gap: 45px;
      padding-bottom: 45px;
  }

  .nav-links.show {
      display: flex;
  }

  .nav-links li {
      margin: 5px 0;
      text-align: center;
  }
}

/* Background fade-in animation */
.header.fade-in {
    animation: fadeIn 1.5s ease-in-out forwards;
  }
  
  /* Navbar slide-down animation */
  .navbar-container {
    transform: translateY(-100%);
    opacity: 0;
    transition: transform 0.7s ease-out, opacity 0.7s ease-out;
  }
  
  .navbar-container.slide-down {
    transform: translateY(0);
    opacity: 1;
  }
  @media (max-width: 768px){
    .changeBttn{
      display: none;
    }
    header {
      padding-top:20px;
      padding-bottom:20px;
  }
  }

  